import React from 'react';

import Layout from '../../components/Layout';
import Feature from '../../components/Feature';
import theme from '../../theme';
import DashboardIcon from '../../images/analytics.jpg';
import B1 from '../../images/new/tab_fct1.jpg';
import B2 from '../../images/new/tab_fct2.jpg';
import B3 from '../../images/new/tab_fct3.jpg';

const DashboardPage = () => (
  <Layout>
    <Feature
      previous="/features/openinno"
      color={theme.palette.primary.main}
      title="Tableau de bord"
      icon={DashboardIcon}
      subtitle="Mesurer l’impact et l’efficacité de toutes vos démarches."
      description="Pilotez, mesurez et analysez facilement vos démarches d'innovation. Analysez d'un coup d'œil l'activité sur votre campus, identifiez les profils les plus actifs, relancez les projets d’innovation au ralenti, agissez au quotidien et pérennisez ainsi toutes vos activités. Un outil indispensable pour rendre compte de l’impact de vos démarches auprès de votre direction et vos collaborateurs."
      subSections={[
        'Piloter simplement vos démarches',
        'Analyser toutes vos activités',
        'Identifier les profils moteurs',
      ]}
      sections={[
        {
          title: 'Analyse de vos démarches',
          description:
            'Analysez d’un coup d’œil et exportez l’activité sur votre campus via un tableau de bord dédié par bâtiment.',
          image: B1,
        },
        {
          title: 'Recherche de contenus',
          description:
            'Retrouvez facilement tous les contenus autour d’une thématique au travers de tous les bâtiments.',
          image: B2,
        },
        {
          title: 'Recherche de compétences',
          description:
            'Trouvez les profils les plus pertinents pour booster un projet, renforcer une équipe ou répondre à une problématique.',
          image: B3,
        },
      ]}
    />
  </Layout>
);

export default DashboardPage;
